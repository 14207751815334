import React, { useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Grid,
  GridItem,
  Icon,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";

const QRCodeGenerator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, wifiName, wifiCode } = location.state || {};
  const [isMobile] = useMediaQuery("(max-width: 425px)");

  const [link] = useState(
    `${process.env.REACT_APP_FRONTEND_URL}/scannedqr/${id}`
  );

  const handleRedirectHomePage = () => {
    navigate("/");
  };

  return (
    <>
      <div className="QRCodePage">
        <Container className="qrcode-container">
          <Row>
            <Col xs={12} md={isMobile ? 12 : 6} className="column-flex">
              <Row>
                <Col xs={12} md={12} className="column-flex">
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/nlexpress_logo.png`}
                    width="100%"
                    rounded
                    fluid
                  />
                </Col>
                <Col>
                  <Grid className="user-checkin-grid">
                    <GridItem>
                      <Flex minWidth="max-content" flexDirection="column">
                        <Link
                          href="https://www.facebook.com/NLEXexpressways"
                          isExternal
                          color="white"
                          fontSize={isMobile ? "lg" : "3xl"}
                          fontWeight="700"
                          alignContent="center"
                          textAlign={isMobile ? "center" : ""}
                        >
                          <Icon
                            as={FaFacebook}
                            color="blue"
                            fontSize={isMobile ? "25px" : "30px"}
                            mr="10px"
                          />
                          NLEX Corporation
                        </Link>
                        <Link
                          href="https://www.instagram.com/nlexexpressways"
                          isExternal
                          color="white"
                          fontSize={isMobile ? "lg" : "3xl"}
                          fontWeight="700"
                          alignContent="center"
                          mt={isMobile ? "10px" : "20px"}
                          textAlign={isMobile ? "center" : ""}
                        >
                          <Icon
                            as={FaInstagram}
                            color="#9d02f2"
                            fontSize={isMobile ? "25px" : "30px"}
                            mr="10px"
                          />
                          NLEX Corporation
                        </Link>
                        <Link
                          href="https://x.com/NLEXexpressways"
                          isExternal
                          color="white"
                          fontSize={isMobile ? "lg" : "3xl"}
                          fontWeight="700"
                          alignContent="center"
                          mt={isMobile ? "10px" : "20px"}
                          textAlign={isMobile ? "center" : ""}
                        >
                          <Icon
                            as={FaSquareXTwitter}
                            color="black"
                            fontSize={isMobile ? "25px" : "30px"}
                            mr="10px"
                          />
                          NLEX Corporation
                        </Link>
                      </Flex>
                    </GridItem>
                  </Grid>
                </Col>
              </Row>
            </Col>
            <Col className="text-center margin-top-30">
              <div className="qrcode-title">
                <h3>
                  Please take a photo of this QR Code and this will be used for
                  Check Out
                </h3>
              </div>
              <div className="qrcode-code">
                <QRCodeSVG value={link} size={256} />
              </div>
              <div className="qrcode-wifi">
                <h3>WIFI Name: {wifiName}</h3>
                <h3>WIFI Code: {wifiCode}</h3>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} md={isMobile ? 12 : 6}>
              <div className="qrcode-list">
                <ul>
                  <li>QR Code generated after check in</li>
                  <li>This QR Code will be scanned by the receptionist</li>
                  <li>WIFI Code will be also generated to connect</li>
                  <li>This QR Code will be scanned for Check Out</li>
                </ul>
              </div>
            </Col>
            <Col md={6} className="qrcode-button">
              <Button
                size="lg"
                variant="secondary"
                onClick={handleRedirectHomePage}
              >
                <b>Back to Home</b>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default QRCodeGenerator;
