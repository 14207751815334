import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const CheckInEmployeePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const handleRedirectHomePage = () => {
    navigate("/");
  };

  const handleRedirectCheckIn = () => {
    navigate(`/employeecheckin/${id}`);
  };

  const handleRedirectCheckOut = () => {
    navigate(`/logoutEmployee/${id}`);
  };

  useEffect(() => {
    const getUser = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getEmployeeDetails?id=${id}`
      );

      if (response.status === 200) {
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
        setIsDisabled(response.data.data.has_check_in);
      }
    };

    getUser();
  }, [id]);

  return (
    <>
      <div className="CheckInPage">
        <Container className="checkin-nlex-container">
          <Row className="checkin-nlex-container-row">
            <Col>
              <h2 className="font-size-small">
                <b>
                  Welcome to NLEX, {firstName} {lastName} !
                </b>
              </h2>
            </Col>
          </Row>
          <Row className="mb-5 mt-5 checkin-title-row">
            <p>What do you want to do?</p>
          </Row>
          <Row className="checkin-button-row">
            <Button
              className="checkin-button"
              disabled={isDisabled}
              style={{ backgroundColor: isDisabled ? "#FFCCCC" : "#F9A01B" }}
              onClick={handleRedirectCheckIn}
            >
              CHECK IN
            </Button>
          </Row>
          <Row className="checkin-button-row">
            <Button className="checkin-button" onClick={handleRedirectCheckOut}>
              CHECK OUT
            </Button>
          </Row>
          <Row className="checkin-button-row">
            <Button
              className="checkin-button-secondary"
              variant="secondary"
              onClick={handleRedirectHomePage}
            >
              BACK TO HOME
            </Button>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CheckInEmployeePage;
