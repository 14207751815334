import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import HomePage from "./HomePage";
import LoginGuest from "./LoginGuest";
import LoginNlex from "./LoginNlex";
import Logout from "./Logout";
import QRCodeGenerator from "./QRCode";
import QRCodeEmployeeGenerator from "./QRCodeEmployee";
import LogoutEmployee from "./LogoutEmployee";
import CheckOut from "./CheckOut";
import CheckInPage from "./CheckInPage";
import UserCheckIn from "./UserCheckIn";
import CheckInEmployeePage from "./CheckInEmployeePage";
import EmployeeCheckIn from "./EmployeeCheckIn";
import AdminGuest from "./AdminGuest";
import AdminEmployee from "./AdminEmployee";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "semantic-ui-css/semantic.min.css";

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/loginGuest" element={<LoginGuest />} />
          <Route path="/loginNlex" element={<LoginNlex />} />
          <Route path="/logout/:id" element={<Logout />} />
          <Route path="/qrcode" element={<QRCodeGenerator />} />
          <Route path="/qrcodeEmployee" element={<QRCodeEmployeeGenerator />} />
          <Route path="/logoutEmployee/:id" element={<LogoutEmployee />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/scannedqr/:id" element={<CheckInPage />} />
          <Route path="/checkin/:id" element={<UserCheckIn />} />
          <Route
            path="/scannedemployeeqr/:id"
            element={<CheckInEmployeePage />}
          />
          <Route path="/employeecheckin/:id" element={<EmployeeCheckIn />} />
          <Route path="/adminguest" element={<AdminGuest />} />
          <Route path="/adminemployee" element={<AdminEmployee />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
