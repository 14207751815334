import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import axios from "axios";

const AdminEmployee = () => {
  const [employees, setEmployee] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/getAllEmployees`)
      .then((response) => {
        setEmployee(response.data.data);
      });
  }, []);

  return (
    <>
      <TableContainer>
        <Table variant="simple" size="lg">
          <TableCaption>Employees List</TableCaption>
          <Thead>
            <Tr>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Employee Number</Th>
              <Th>Division</Th>
              <Th>Purpose</Th>
              <Th>Location</Th>
              <Th>Check In Time</Th>
              <Th>Check Out Time</Th>
              <Th>Has Checked In</Th>
              <Th>Has Checked Out</Th>
            </Tr>
          </Thead>
          <Tbody>
            {employees.map((employee) => (
              <Tr>
                <Td>{employee.first_name}</Td>
                <Td>{employee.last_name}</Td>
                <Td>{employee.employee_number}</Td>
                <Td>{employee.division}</Td>
                <Td>{employee.purpose}</Td>
                <Td>{employee.location}</Td>
                <Td>{employee.check_in_time}</Td>
                <Td>{employee.check_out_time}</Td>
                <Td>
                  {employee.has_check_in === 0 ? (
                    <Tag colorScheme="red" borderRadius="full">
                      <TagLabel>No</TagLabel>
                    </Tag>
                  ) : (
                    <Tag colorScheme="green" borderRadius="full">
                      <TagLabel>Yes</TagLabel>
                    </Tag>
                  )}
                </Td>
                <Td>
                  {employee.is_active === 1 ? (
                    <Tag colorScheme="red" borderRadius="full">
                      <TagLabel>No</TagLabel>
                    </Tag>
                  ) : (
                    <Tag colorScheme="green" borderRadius="full">
                      <TagLabel>Yes</TagLabel>
                    </Tag>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
          <Tr>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Employee Number</Th>
              <Th>Division</Th>
              <Th>Purpose</Th>
              <Th>Location</Th>
              <Th>Check In Time</Th>
              <Th>Check Out Time</Th>
              <Th>Has Checked In</Th>
              <Th>Has Checked Out</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminEmployee;
