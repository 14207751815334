import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  GridItem,
  Image,
  Link,
  Icon,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Checkbox,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

const HomePage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 425px)");

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleClose = () => {
    if (isChecked) {
      setShowModal(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleRedirectLoginGuest = () => {
    navigate("/loginGuest");
  };

  const handleRedirectLoginNlex = () => {
    navigate("/loginNlex");
  };

  const handleRedirectCheckOut = () => {
    navigate("/checkout");
  };

  return (
    <>
      <div className="App">
        <Container
          borderRadius="15px"
          centerContent
          className="home-container home-container-small"
        >
          <Grid
            h="60vh"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(12, 1fr)"
            gap={4}
          >
            <GridItem
              rowSpan={1}
              colSpan={12}
              className="home-grid-item home-grid-item-small"
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/nlexpress_logo.png`}
                maxWidth={isMobile ? "100%" : "40%"}
                margin="auto"
                marginTop={isMobile ? "20px" : "0px"}
              />
              <Image
                src={`${process.env.PUBLIC_URL}/images/nrw_logo.png`}
                maxWidth={isMobile ? "60%" : "15%"}
                margin="auto"
                marginTop={isMobile ? "20px" : "0px"}
              />
            </GridItem>
            <GridItem
              rowSpan={1}
              colSpan={isMobile ? 12 : 6}
              mt={isMobile ? "10px" : "50px"}
              mb={isMobile ? "10px" : "20px"}
            >
              <Flex minWidth="max-content" flexDirection="column">
                <Link
                  href="https://www.facebook.com/NLEXexpressways"
                  isExternal
                  fontSize={isMobile ? "md" : "xl"}
                  color="white"
                  fontWeight="700"
                >
                  <Icon
                    as={FaFacebook}
                    color="blue"
                    fontSize={isMobile ? "20px" : "30px"}
                    mr="10px"
                  />{" "}
                  NLEX Corporation
                </Link>
                <Link
                  href="https://www.instagram.com/nlexexpressways"
                  isExternal
                  fontSize={isMobile ? "md" : "xl"}
                  color="white"
                  fontWeight="700"
                  mt={isMobile ? "10px" : "30px"}
                >
                  <Icon
                    as={FaInstagram}
                    color="#9d02f2"
                    fontSize={isMobile ? "md" : "xl"}
                    mr="10px"
                  />{" "}
                  NLEX Corporation
                </Link>
                <Link
                  href="https://x.com/NLEXexpressways"
                  isExternal
                  fontSize={isMobile ? "md" : "xl"}
                  color="white"
                  fontWeight="700"
                  mt={isMobile ? "10px" : "30px"}
                >
                  <Icon
                    as={FaSquareXTwitter}
                    color="black"
                    fontSize={isMobile ? "md" : "xl"}
                    mr="10px"
                  />{" "}
                  NLEX Corporation
                </Link>
              </Flex>
            </GridItem>
            <GridItem
              rowSpan={1}
              colSpan={isMobile ? 12 : 5}
              mb={isMobile ? "10px" : "20px"}
              mt={isMobile ? "20px" : "0px"}
            >
              <Flex
                minWidth="max-content"
                flexDirection="column"
                flexWrap="wrap"
              >
                <Button
                  size={isMobile ? "md" : "lg"}
                  bg="#F9A01B"
                  onClick={handleRedirectLoginGuest}
                  fontWeight="semibold"
                  _hover={{ bg: "#104B89", color: "white" }}
                >
                  CHECK IN AS GUEST
                </Button>
                <Button
                  size={isMobile ? "md" : "lg"}
                  bg="#F9A01B"
                  onClick={handleRedirectLoginNlex}
                  fontWeight="semibold"
                  mt={isMobile ? "20px" : "50px"}
                  _hover={{ bg: "#104B89", color: "white" }}
                >
                  CHECK IN AS NLEX
                </Button>
                <Button
                  size={isMobile ? "md" : "lg"}
                  bg="#F9A01B"
                  onClick={handleRedirectCheckOut}
                  fontWeight="semibold"
                  mt={isMobile ? "20px" : "50px"}
                  _hover={{ bg: "#104B89", color: "white" }}
                >
                  CHECK OUT
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </Container>
      </div>

      <Modal
        isOpen={showModal}
        onClose={handleClose}
        closeOnOverlayClick={false}
        size={isMobile ? "md" : "xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>DATA PRIVACY AND PERSONAL DATA CONSENT FORM</ModalHeader>
          <ModalBody>
            <Text>
              NLEX Corporation protect all information you provide in compliance
              with the Data Privacy Act of 2012 and its implementing rules and
              regulations ("Data Privacy Laws"). NLEX Corporation shall not
              collect, disclose or process any such personal information unless
              you voluntarily choose to provide us with it, give your consent,
              or unless such disclosure is required by applicable laws and
              regulations.
            </Text>
            <Text>
              By signing this form, you confirm that you freely and voluntarily
              give consent to the collection and processing of your personal
              information, which may include personal information and/or
              sensitive personal information (hereafter the "Data") set out in
              this form and/or otherwise provided by you or possessed by NLEX
              Corporation.
            </Text>
            <Text>
              NLEX Corporation shall keep the Data for a period of ten (10)
              years for record purposes, among others. NLEX Corporation shall
              take appropriate and commercially reasonable technical and
              organizational measures to ensure the required data security to
              protect the Data against unauthorized disclosure, access or
              processing. NLEX Corporation shall require its affiliates,
              subsidiaries and third parties who process the Data to comply with
              the requirements of the Data Privacy Laws.
            </Text>
            <Text>
              You understand that you are given rights under the Data Privacy
              Laws, including the right to: object to the processing of your
              data, access your data, correct any inaccurate data, and erasure
              or blocking of your data. For more information on these rights,
              and for requests to exercise your rights under the Data Privacy
              Laws, please contact NLEX Corporation through 1-35000.
            </Text>
            <Text>
              By signing this form, your personal information will be collected
              and processed by NLEX Corporation, and you further agree and
              consent to its transfer, processing, and disclosure as further
              stated in the terms nad conditions which shall be considered as
              integral part of this form.
            </Text>
            <Checkbox mt={4} onChange={handleCheckboxChange}>
              I have read and understood this.
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleClose}
              isDisabled={!isChecked}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HomePage;
