import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import QrReader from "react-qr-scanner";
import { useNavigate } from "react-router-dom";

const CheckOut = () => {
  const navigate = useNavigate();
  const [scanResult, setScanResult] = useState(
    "Hold QR Steady and Clear to Scan"
  );
  const [error, setError] = useState("");

  const handleRedirectHomePage = () => {
    navigate("/");
  };

  const handleScan = (data) => {
    if (data) {
      setScanResult(data);
      if (data.startsWith("http://") || data.startsWith("https://")) {
        window.location.href = data;
      } else {
        alert("Scanned data is not a valid URL!");
      }
    }
  };

  const handleError = (err) => {
    console.log(err);
    setError(
      "Error accessing the camera. Please ensure camera permissions are granted!"
    );
  };

  const camStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "-50px",
  };

  const previewStyle = {
    height: 700,
    width: 1000,
    display: "flex",
    "justify-content": "center",
  };

  return (
    <div className="Checkout">
      <Container className="checkout-container">
        <Row className="checkout-container-row">
          <Col>
            <h2>
              <b>Hold QR Steady and Clear to Scan</b>
            </h2>
          </Col>
        </Row>
        <Row>
          <div style={camStyle}>
            <QrReader
              delay={100}
              onError={handleError}
              onScan={handleScan}
              style={previewStyle}
            />
          </div>
        </Row>
        <Row className="checkout-button-row">
          <Button
            className="checkin-button-secondary"
            variant="secondary"
            onClick={handleRedirectHomePage}
          >
            Back to Home
          </Button>
        </Row>
      </Container>
    </div>
  );
};

export default CheckOut;
