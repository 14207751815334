import React, { useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";

function LoginGuest() {
  const navigate = useNavigate();
  const [showMeetingInput, setShowMeetingInput] = useState(true);
  const [showDeliveryInput, setShowDeliveryInput] = useState(false);
  const [showContractorInput, setShowContractorInput] = useState(false);
  const [showVisitorInput, setShowVisitorInput] = useState(false);
  const [showCustomerServiceInput, setShowCustomerServiceInput] =
    useState(false);
  const [validated, setValidated] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [image, setImage] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });
  const [formData, setFormData] = useState({
    last_name: "",
    first_name: "",
    company_name: "",
    contact_number: "",
    purpose: "Meeting",
    start_time: "",
    end_time: "",
    nlex_contact: "",
    id_photo: "",
    location: "",
    email_address: "",
    type_of_item: "",
    plate_number: "",
    rfid_account: "",
    concern: "",
  });
  const contacts = [
    { key: 2, value: "jsaquino@nlex.com.ph", text: "Janeth Aquino" },
    { key: 3, value: "ydjose@nlex.com.ph", text: "Yrwina Faye Jose" },
    { key: 4, value: "cbrivera@nlex.com.ph", text: "Camela Rivera" },
    { key: 5, value: "ralumilan@nlex.com.ph", text: "Ria Joyce Lumilan" },
    { key: 6, value: "kscastro@nlex.com.ph", text: "Kathleen Castro" },
    { key: 7, value: "pvlagman@nlex.com.ph", text: "Patricia Ann Lagman" },
    { key: 8, value: "kkbalane@nlex.com.ph", text: "Kylla Andrea Balane" },
  ];

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;

    if (e.target.value === "Meeting") {
      setShowMeetingInput(true);
      setShowDeliveryInput(false);
      setShowContractorInput(false);
      setShowVisitorInput(false);
      setShowCustomerServiceInput(false);
    } else if (e.target.value === "Delivery") {
      setShowMeetingInput(false);
      setShowDeliveryInput(true);
      setShowContractorInput(false);
      setShowVisitorInput(false);
      setShowCustomerServiceInput(false);
    } else if (e.target.value === "Contractor") {
      setShowMeetingInput(false);
      setShowDeliveryInput(false);
      setShowContractorInput(true);
      setShowVisitorInput(false);
      setShowCustomerServiceInput(false);
    } else if (e.target.value === "Visitor") {
      setShowMeetingInput(false);
      setShowDeliveryInput(false);
      setShowContractorInput(false);
      setShowVisitorInput(true);
      setShowCustomerServiceInput(false);
    } else if (e.target.value === "Customer Service") {
      setShowMeetingInput(false);
      setShowDeliveryInput(false);
      setShowContractorInput(false);
      setShowVisitorInput(false);
      setShowCustomerServiceInput(true);
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContactDropdownChange = (e, { value, name }) => {
    const result = contacts.find((contact) => contact.value === value);

    setFormData((formData) => ({
      ...formData,
      [name]: value,
      nlex_contact: result.text,
    }));
  };

  const handleRedirectHomePage = () => {
    navigate("/");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    const data = new FormData();
    data.append("last_name", formData.last_name);
    data.append("first_name", formData.first_name);
    data.append("company_name", formData.company_name);
    data.append("contact_number", formData.contact_number);
    data.append("purpose", formData.purpose);
    data.append("start_time", startTime);
    data.append("end_time", endTime);
    data.append("nlex_contact", formData.nlex_contact);
    data.append("id_photo", image);
    data.append("location", formData.location);
    data.append("email_address", formData.email_address);
    data.append("type_of_item", formData.type_of_item);
    data.append("plate_number", formData.plate_number);
    data.append("rfid_account", formData.rfid_account);
    data.append("concern", formData.concern);

    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/saveGuest`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200 && formData.purpose === "Meeting") {
          navigate("/qrcode", {
            state: {
              id: response.data.id,
              wifiName: response.data.wifiName,
              wifiCode: response.data.wifiCode,
            },
          });
        } else if (
          response.status === 200 &&
          (formData.purpose === "Delivery" ||
            formData.purpose === "Contractor" ||
            formData.purpose === "Visitor" ||
            formData.purpose === "Customer Service")
        ) {
          navigate("/");
        }
      } catch (error) {
        console.error("Error saving data: ", error);
      }
    }

    setValidated(true);
  };

  return (
    <>
      <div className="LoginGuest">
        <Container className="login-guest-container login-guest-container-small">
          <Row className="login-guest-container-row">
            <Col>
              <h1 className="font-size-small">
                <b>CHECK IN AS GUEST</b>
              </h1>
            </Col>
          </Row>
          <Row>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="fs-5 fw-bold text-start"
            >
              <Row
                className={
                  isMobile
                    ? "mt-3 login-guest-row"
                    : "mb-3 mt-5 login-guest-row"
                }
              >
                <Form.Group
                  as={Col}
                  xs={12}
                  md={isMobile ? 12 : 6}
                  controlId="last_name"
                  className="mt-2 mb-2"
                >
                  <Form.Label className="label-small">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    size="md"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid" className="label-small">
                    Please input a Last Name!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  xs={12}
                  md={isMobile ? 12 : 6}
                  controlId="first_name"
                  className="mt-2 mb-2"
                >
                  <Form.Label className="label-small">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    size="md"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid" className="label-small">
                    Please input a Last Name!
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row
                className={
                  isMobile ? "login-guest-row" : "mb-3 mt-3 login-guest-row"
                }
              >
                <Form.Group
                  as={Col}
                  xs={12}
                  md={isMobile ? 12 : 6}
                  controlId="company_name"
                  className="mt-2 mb-2"
                >
                  <Form.Label className="label-small">Company</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Company Name"
                    size="md"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid" className="label-small">
                    Please input Company Name!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  xs={12}
                  md={isMobile ? 12 : 6}
                  controlId="contact_number"
                  className="mt-2 mb-2"
                >
                  <Form.Label className="label-small">
                    Contact Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Contact Number"
                    size="md"
                    name="contact_number"
                    value={formData.contact_number}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid" className="label-small">
                    Please input Contact Number!
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row
                className={
                  isMobile
                    ? "login-guest-row"
                    : "mb-3 mt-3 justify-content-md-center login-guest-row"
                }
              >
                <Form.Group
                  as={Col}
                  xs={12}
                  md={isMobile ? 12 : 6}
                  controlId="purpose"
                  className="mt-2 mb-2"
                >
                  <Form.Label className="label-small">Purpose</Form.Label>
                  <Form.Select
                    aria-label="Enter your purpose"
                    size="md"
                    name="purpose"
                    value={formData.purpose}
                    onChange={handleDropdownChange}
                  >
                    <option value="Meeting">Meeting</option>
                    <option value="Delivery">Delivery</option>
                    <option value="Contractor">Contractor</option>
                    <option value="Visitor">Personal Visitor</option>
                    <option value="Customer Service">Customer Service</option>
                  </Form.Select>
                </Form.Group>
              </Row>

              {showMeetingInput && (
                <>
                  <Row
                    className={
                      isMobile ? "login-guest-row" : "mb-3 mt-3 login-guest-row"
                    }
                  >
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={isMobile ? 12 : 6}
                      controlId="start_time"
                      className="mt-2 mb-2"
                    >
                      <Form.Label
                        style={{ width: "100%" }}
                        className="label-small"
                      >
                        Start Time of Meeting
                      </Form.Label>
                      <DatePicker
                        showTimeSelect
                        minTime={new Date(0, 0, 0, 8, 0)}
                        maxTime={new Date(0, 0, 0, 19, 0)}
                        minDate={moment().toDate()}
                        selected={startTime}
                        onChange={(startTime) => setStartTime(startTime)}
                        dateFormat="MMMM d, yyyy h:mmaa"
                        className="form-control form-control-md datepicker-100"
                        style={{ width: "80%" }}
                      ></DatePicker>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      xs={12}
                      md={isMobile ? 12 : 6}
                      controlId="end_time"
                      className="mt-2 mb-2"
                    >
                      <Form.Label
                        style={{ width: "100%" }}
                        className="label-small"
                      >
                        End Time of Meeting
                      </Form.Label>
                      <DatePicker
                        showTimeSelect
                        minTime={new Date(0, 0, 0, 8, 0)}
                        maxTime={new Date(0, 0, 0, 19, 0)}
                        minDate={moment().toDate()}
                        selected={endTime}
                        onChange={(endTime) => setEndTime(endTime)}
                        dateFormat="MMMM d, yyyy h:mmaa"
                        className="form-control form-control-md datepicker-100"
                      ></DatePicker>
                    </Form.Group>
                  </Row>

                  <Row
                    className={
                      isMobile ? "login-guest-row" : "mb-3 mt-3 login-guest-row"
                    }
                  >
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={isMobile ? 12 : 6}
                      controlId="location"
                      className="mt-2 mb-2"
                    >
                      <Form.Label className="label-small">Location</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Location"
                        size="md"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      xs={12}
                      md={isMobile ? 12 : 6}
                      controlId="formGridIdPhoto"
                      className="mt-2 mb-2"
                    >
                      <Form.Label className="label-small">ID Photo</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Enter Contact Number"
                        size="md"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Form.Group>
                  </Row>

                  <Row
                    className={
                      isMobile ? "login-guest-row" : "mb-3 mt-3 login-guest-row"
                    }
                  >
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={isMobile ? 12 : 6}
                      controlId="email_address"
                      className="mt-2 mb-2"
                    >
                      <Form.Label className="label-small">
                        NLEX Contact Person
                      </Form.Label>
                      <Dropdown
                        placeholder="Enter NLEX Contact Person Name"
                        fluid
                        search
                        selection
                        options={contacts}
                        className="dropdown-size"
                        onChange={handleContactDropdownChange}
                        name="email_address"
                        size="md"
                      />
                    </Form.Group>
                  </Row>
                </>
              )}

              {showDeliveryInput && (
                <>
                  <Row className="mb-3 mt-3 login-guest-row">
                    <Form.Group
                      as={Col}
                      controlId="type_of_item"
                      className="mt-2 mb-2"
                    >
                      <Form.Label>Type of Item</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="(Documents, Food, etc.)"
                        size="md"
                        name="type_of_item"
                        value={formData.type_of_item}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="email_address"
                      className="mt-2 mb-2"
                      xs={6}
                      md={6}
                    >
                      <Form.Label>NLEX Contact Person</Form.Label>
                      <Dropdown
                        placeholder="Enter NLEX Contact Person Name"
                        fluid
                        search
                        selection
                        options={contacts}
                        style={{ width: "80%" }}
                        onChange={handleContactDropdownChange}
                        name="email_address"
                        size="md"
                      />
                    </Form.Group>
                  </Row>
                </>
              )}

              {(showContractorInput || showVisitorInput) && (
                <>
                  <Row className="mb-3 mt-3 login-guest-row">
                    <Form.Group
                      as={Col}
                      controlId="location"
                      className="mt-2 mb-2"
                      xs={6}
                      md={6}
                    >
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Location"
                        size="md"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="formGridIdPhoto"
                      className="mt-2 mb-2"
                    >
                      <Form.Label>ID Photo</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Enter Contact Number"
                        size="md"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 mt-3 login-guest-row">
                    <Form.Group
                      as={Col}
                      controlId="email_address"
                      className="mt-2 mb-2"
                      xs={6}
                      md={6}
                    >
                      <Form.Label>NLEX Contact Person</Form.Label>
                      <Dropdown
                        placeholder="Enter NLEX Contact Person Name"
                        fluid
                        search
                        selection
                        options={contacts}
                        style={{ width: "80%" }}
                        onChange={handleContactDropdownChange}
                        name="email_address"
                        size="md"
                      />
                    </Form.Group>
                  </Row>
                </>
              )}

              {showCustomerServiceInput && (
                <>
                  <Row className="mb-3 mt-3 login-guest-row">
                    <Form.Group
                      as={Col}
                      controlId="plate_number"
                      className="mt-2 mb-2"
                      xs={6}
                      md={6}
                    >
                      <Form.Label>Plate Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Plate Number"
                        size="md"
                        name="plate_number"
                        value={formData.plate_number}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="rfid_account"
                      className="mt-2 mb-2"
                    >
                      <Form.Label>RFID Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter RFID Account Number"
                        size="md"
                        name="rfid_account"
                        value={formData.rfid_account}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 mt-3 login-guest-row">
                    <Form.Group
                      as={Col}
                      controlId="concern"
                      className="mt-2 mb-2"
                      xs={6}
                      md={6}
                    >
                      <Form.Label>Concern</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Specify your Concern"
                        size="md"
                        name="concern"
                        value={formData.concern}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                </>
              )}

              <Row
                className={
                  isMobile
                    ? "login-guest-row"
                    : "mb-3 mt-3 justify-content-md-center"
                }
              >
                <Col style={{ "text-align": "center" }}>
                  <Button
                    type="submit"
                    variant="warning submit-button"
                    className="login-guest-button"
                  >
                    Submit
                  </Button>
                  <Button
                    variant="secondary submit-button"
                    onClick={handleRedirectHomePage}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LoginGuest;
