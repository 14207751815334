import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Image,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import axios from "axios";

const AdminGuest = () => {
  const [guests, setGuests] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/getAllGuests`)
      .then((response) => {
        setGuests(response.data.data);
      });
  }, []);

  return (
    <>
      <TableContainer>
        <Table variant="simple" size="lg">
          <TableCaption>Guests List</TableCaption>
          <Thead>
            <Tr>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Company</Th>
              <Th>Contact Number</Th>
              <Th>Purpose</Th>
              <Th>Start Time</Th>
              <Th>End Time</Th>
              <Th>NLEX Contact Person</Th>
              <Th>ID Photo</Th>
              <Th>Location</Th>
              <Th>NLEX Contact Email Address</Th>
              <Th>Type of Item</Th>
              <Th>Check In Time</Th>
              <Th>Check Out Time</Th>
              <Th>Has Checked In</Th>
              <Th>Plate Number</Th>
              <Th>RFID Account</Th>
              <Th>Concern</Th>
              <Th>Has Checked Out</Th>
            </Tr>
          </Thead>
          <Tbody>
            {guests.map((guest) => (
              <Tr>
                <Td>{guest.first_name}</Td>
                <Td>{guest.last_name}</Td>
                <Td>{guest.company_name}</Td>
                <Td>{guest.contact_number}</Td>
                <Td>{guest.purpose}</Td>
                <Td>{guest.start_time}</Td>
                <Td>{guest.end_time}</Td>
                <Td>{guest.nlex_contact}</Td>
                <Td>
                  <Image src={guest.id_photo} />
                </Td>
                <Td>{guest.location}</Td>
                <Td>{guest.email_address}</Td>
                <Td>{guest.type_of_item}</Td>
                <Td>{guest.check_in_time}</Td>
                <Td>{guest.check_out_time}</Td>
                <Td>
                  {guest.has_check_in === 0 ? (
                    <Tag colorScheme="red" borderRadius="full">
                      <TagLabel>No</TagLabel>
                    </Tag>
                  ) : (
                    <Tag colorScheme="green" borderRadius="full">
                      <TagLabel>Yes</TagLabel>
                    </Tag>
                  )}
                </Td>
                <Td>{guest.plate_number}</Td>
                <Td>{guest.rfid_account}</Td>
                <Td>{guest.concern}</Td>
                <Td>
                  {guest.is_active === 1 ? (
                    <Tag colorScheme="red" borderRadius="full">
                      <TagLabel>No</TagLabel>
                    </Tag>
                  ) : (
                    <Tag colorScheme="green" borderRadius="full">
                      <TagLabel>Yes</TagLabel>
                    </Tag>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Company</Th>
              <Th>Contact Number</Th>
              <Th>Purpose</Th>
              <Th>Start Time</Th>
              <Th>End Time</Th>
              <Th>NLEX Contact Person</Th>
              <Th>ID Photo</Th>
              <Th>Location</Th>
              <Th>Email Address</Th>
              <Th>Type of Item</Th>
              <Th>Check In Time</Th>
              <Th>Check Out Time</Th>
              <Th>Has Checked In</Th>
              <Th>Plate Number</Th>
              <Th>RFID Account</Th>
              <Th>Concern</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminGuest;
