import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import {
  Flex,
  GridItem,
  Icon,
  Image,
  Link,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Grid } from "semantic-ui-react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const EmployeeCheckIn = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 425px)");

  const handleRedirectHomePage = () => {
    navigate("/");
  };

  useEffect(() => {
    const getUser = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/getEmployeeDetails?id=${id}`
      );

      if (response.status === 200) {
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
      }

      const data = {
        id: id,
      };

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/updateEmployeeCheckin`,
        data
      );
    };

    getUser();
  }, [id]);

  return (
    <>
      <div className="UserCheckIn">
        <Container className="user-checkin-container">
          <Row className="user-checkin-container-row">
            <Col>
              <h2 className="font-size-small">
                <b>
                  Welcome to NLEX, {firstName} {lastName} !
                </b>
              </h2>
            </Col>
          </Row>
          <Row>
            <Image
              src={`${process.env.PUBLIC_URL}/images/nlexpress_logo.png`}
              maxWidth={isMobile ? "100%" : "50%"}
              margin="auto"
              marginTop={isMobile ? "30px" : "0px"}
            />
          </Row>
          <Row>
            <Col className="user-checkin-col">
              <Text>Please follow us on our social media</Text>
            </Col>
            <Col>
              <Grid className="user-checkin-grid">
                <GridItem>
                  <Flex minWidth="max-content" flexDirection="column">
                    <Link
                      href="https://www.facebook.com/NLEXexpressways"
                      isExternal
                      color="white"
                      fontSize="3xl"
                      fontWeight="700"
                      alignContent="center"
                    >
                      <Icon
                        as={FaFacebook}
                        color="blue"
                        fontSize="30px"
                        mr="10px"
                      />
                      NLEX Corporation
                    </Link>
                    <Link
                      href="https://www.instagram.com/nlexexpressways"
                      isExternal
                      color="white"
                      fontSize="3xl"
                      fontWeight="700"
                      alignContent="center"
                      mt="20px"
                    >
                      <Icon
                        as={FaInstagram}
                        color="#9d02f2"
                        fontSize="30px"
                        mr="10px"
                      />
                      NLEX Corporation
                    </Link>
                    <Link
                      href="https://x.com/NLEXexpressways"
                      isExternal
                      color="white"
                      fontSize="3xl"
                      fontWeight="700"
                      alignContent="center"
                      mt="20px"
                    >
                      <Icon
                        as={FaSquareXTwitter}
                        color="black"
                        fontSize="30px"
                        mr="10px"
                      />
                      NLEX Corporation
                    </Link>
                  </Flex>
                </GridItem>
              </Grid>
            </Col>
          </Row>
          <Row className="user-checkin-button-row">
            <Button
              className="checkin-button-secondary"
              variant="secondary"
              onClick={handleRedirectHomePage}
            >
              Back to Home
            </Button>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EmployeeCheckIn;
